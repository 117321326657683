import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import {
  Header,
  SymptomsTable,
  AgreementsTable,
  Understandings,
  RestrictionsTable,
  Footer,
  SignupForm,
} from '../../components'
import { TextContent, Colors } from '../../constants'
import { registerAcknowledgement } from '../../actions/wellnessAcknowledgements'
import logo from '../../assets/logo.png'

export default () => {
  const numberOfAgreements = TextContent.firstSection.agreements.length

  const [agreementsState, setAgreementsState] = useState(
    Array.from({ length: numberOfAgreements }, (i) => (i = false))
  )

  const handleAcknowledgementSubmission = async (userName, selectedDate) => {
    const agreementsAttested = agreementsState.filter(Boolean).length
    if (agreementsAttested !== numberOfAgreements) {
      return alert(
        'Please attest to all agreements before submitting.'
      )
    }
    await registerAcknowledgement(userName, selectedDate)
    alert(
      'Your wellness acknowledgment has been submitted successfully! You may close this window now'
    )
    window.location.reload()
  }

  const WellnessSignup = () => (
    <div>
    <div
      style={{
        margin: '10px 10px 10px 0',
        textAlign: 'right',
      }}
    >
      <div>
        <img src={logo} alt="Logo" />
      </div>
    </div>

    <Grid item container justify="center">
    

      <Header {...TextContent.firstSection.header} />
      <Container>
        <Grid item xs={12}>
          <p>
            {TextContent.firstSection.firstParagraph}
          </p>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <SymptomsTable />
      </Grid>
      <Container>
        <Grid item xs={12}>
          <AgreementsTable
            agreementsState={agreementsState}
            setAgreementsState={setAgreementsState}
          />
        </Grid>
        <Grid item xs={12}>
          <Understandings />
        </Grid>
      </Container>

      <Container>
        <Grid item container justify="center">
          <h3>Employee Acknowledgment</h3>
        </Grid>          
      </Container>
      <Grid item xs={12}>
        <SignupForm handleSubmit={handleAcknowledgementSubmission} />
      </Grid>

      <Header {...TextContent.secondSection.header} />
      <Container>
        <Grid item xs={12}>
          <h2
            style={{ color: Colors.secondary}}>
            {TextContent.secondSection.bodyHeader}
          </h2>
          <i style={{ fontSize: '16px' }}>
            {TextContent.secondSection.bodyDescription}
          </i>
        </Grid>
        <Grid item xs={12}>
          <RestrictionsTable />
        </Grid>
      </Container>

      <Footer content={`Revised November 26, 2020\nRailPros Inc.`} />
    </Grid>
    </div>
  )

  return (
    <Grid container direction="row" style={{ height: '100vh' }}>
      <Grid item xs={12}>
        <WellnessSignup />
      </Grid>
    </Grid>
  )
}
