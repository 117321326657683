import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  }
}))

export default ({ handleSubmit }) => {
  const [userName, setUserName] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())

  const validateInput = () => {
    if (!userName.trim()) {
      return alert(
        'Please make sure to fill in your name before submitting your wellness acknowledgment.'
      )
    }

    handleSubmit(userName, selectedDate)
  }

  const classes = useStyles()
  return (
    <Container>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={3}>
          <TextField
            id="name"
            label="Full Name"
            className={classes.fullWidth}
            value={userName}
            onChange={({ target: { value } }) => setUserName(value)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disabled
              className={classes.fullWidth}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date"
              value={selectedDate}
              onChange={setSelectedDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <Button
            className={classes.fullWidth}
            variant="contained"
            onClick={validateInput}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
        <div style={{ marginTop: '10px' }}>
          <p>For a list of work rules for ill employees, please see the following list of exclusions and restrictions below:</p>
        </div>
    </Container>
  )
}
