import React from 'react'
import { Colors } from '../constants'

export default ({ title, content }) => (
  <div
    style={{
      backgroundColor: Colors.primary,
      padding: '20px',
      marginBottom: '10px',
      width: '100%'
    }}>
    <h1 style={{ color: 'white'}}>
      {title}
    </h1>
    {content && (
      <h3 style={{ color: 'white'}}>
        {content}
      </h3>
    )}
  </div>
)
