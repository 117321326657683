import React from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { Colors, TextContent, Styles } from '../constants'

export default () => (
  <div>
    <div
      style={{
        backgroundColor: Colors.secondary,
        textAlign: 'center',
        marginTop: '10px',
      }}>
      <p style={{ color: 'white', fontWeight: 'bold' }}>
        {TextContent.firstSection.symptomsTable.title}
      </p>
    </div>
    <Container>
      <div
        style={{
          ...Styles.tableCell,
          fontSize: '16px',
        }}>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <ol>
              {TextContent.firstSection.symptomsTable.data
                .slice(0, 5)
                .map((symptom, symptomIndex) => (
                  <li key={`symptom-${symptomIndex}-${symptom}`}>{symptom}</li>
                ))}
            </ol>
          </Grid>
          <Grid item xs={6}>
            <ol>
              {TextContent.firstSection.symptomsTable.data
                .slice(5)
                .map((symptom, symptomIndex) =>
                  symptomIndex === 0 ? (
                    <li key={`symptom-${symptomIndex}-${symptom}`} value={6}>
                      {symptom}
                    </li>
                  ) : (
                    <li key={`symptom-${symptomIndex}-${symptom}`}>{symptom}</li>
                  )
                )}
            </ol>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          ...Styles.tableCell,
          textAlign: 'center',
        }}>
        <p>
          {TextContent.firstSection.symptomsTable.footer}
        </p>
      </div>
    </Container>
  </div>
)
