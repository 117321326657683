import React from 'react'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import { TextContent } from '../constants'

export default ({ agreementsState, setAgreementsState }) => (
  <div>
    {TextContent.firstSection.agreements.map(
      ({ title, description, bulletPoints }, agreementIndex) => (
        <Grid
          key={`agreement-${agreementIndex}`}
          item
          xs={12}
          style={{ marginTop: '10px' }}
        >
          <Box display="flex" alignItems="center">
            <Checkbox
              color="default"
              checked={agreementsState[agreementIndex]}
              onChange={() => {
                const newAgreementsState = JSON.parse(
                  JSON.stringify(agreementsState)
                )
                newAgreementsState[agreementIndex] = !agreementsState[
                  agreementIndex
                ]
                setAgreementsState(newAgreementsState)
              }}
            />
            <span style={{ fontSize: '16px' }}>{title}</span>
          </Box>
          <div style={{ fontSize: '16px' }}>
            {description && (
              <div style={{ marginTop: '20px' }}><i style={{ fontSize: '16px' }}>{description}</i></div>
            )}
            {bulletPoints && (
              <>
                <p style={{ fontWeight: 'bold' }}>{bulletPoints.title}</p>
                <ul>
                  {bulletPoints.data.map((bulletPoint, bulletPointIndex) => (
                    <li key={`bulletpoint-${bulletPointIndex}-${bulletPoint}`}>
                      {bulletPoint}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </Grid>
      )
    )}
  </div>
)
