// test reducer

const initialState = {
  count: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
