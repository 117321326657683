import axios from 'axios'

export const axiosInstance = axios.create({
  // baseURL: 'https://otss-server-nest-staging.herokuapp.com', // staging
  baseURL: 'https://otss-server-nest-poduction.herokuapp.com',
})

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = 'Lof5BmyPryHWoW2uReOfvBqtBtr6DltB'
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

export default axiosInstance
