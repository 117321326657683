import React from 'react'
import { Colors } from '../constants'

export default ({ content }) => (
  <div
    style={{
      backgroundColor: Colors.primary,
      padding: '25px',
      width: '100%',
      marginTop: '10px',
    }}>
    {content.split('\n').map((footerText, footerTextIndex) => (
      <div key={`footerText-container-${footerTextIndex}-${footerText}`}>
        <span style={{ color: 'white', fontSize: '16px', fontWeight: '300' }}>
          {footerText}
        </span>
      </div>
    ))}
  </div>
)
