import { axiosInstance as axios } from './helpers'

export const customPost = async (url, body) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, body)
      .then((request) => {
        resolve(request.data)
      })
      .catch((error) => {
        console.error(error.message)
        reject(error.message)
      })
  })
