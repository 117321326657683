import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextContent } from '../constants'

export default () => (
  <div>
    {TextContent.firstSection.understandings.map(
      ({ title, data }, understandingIndex) => (
        <Grid
          key={`understanding-${understandingIndex}-${title}`}
          item
          xs={12}
          style={{ marginTop: '10px' }}>
          <p style={{fontWeight: 'bold' }}>{title}</p>
          <ol>
            {data.map((understanding, understandingIndex) => (
              <li
                key={`understanding-${understandingIndex}-${understanding}`}
                style={{ fontSize: '16px' }}>
                {understanding}
              </li>
            ))}
          </ol>
        </Grid>
      )
    )}
  </div>
)
