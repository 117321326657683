import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Colors, TextContent, Styles } from '../constants'

const Headers = ({ data }) => (
  <>
    {data.map((headerContent, headerIndex) => (
      <Grid
        key={`header-${headerIndex}-${headerContent}`}
        item
        xs={headerIndex === 0 ? 2 : 5}
        style={Styles.tableCell}>
        <p style={{ color: 'white', fontWeight: 'bold', fontSize: '12px' }}>
          {headerContent}
        </p>
      </Grid>
    ))}
  </>
)

const CellData = ({ data }) => (
  <>
    {data.body && <span>{data.body}</span>}
    {data.bulletPoints && (
      <ul>
        {data.bulletPoints.map((bulletPoint, bulletPointIndex) => (
          <li key={`bulletPoint-${bulletPointIndex}-${bulletPoint}`}>
            {bulletPoint}
          </li>
        ))}
      </ul>
    )}
    {data.footer && (
      <>
        <br />
        <br />
        <span>{data.footer}</span>
      </>
    )}
  </>
)

const RowContent = ({ data }) => (
  <>
    {data.map((cellData, cellDataIndex) => (
      <Grid
        key={`cell-${cellDataIndex}`}
        item
        xs={cellDataIndex === 0 ? 2 : 5}
        style={Styles.tableCell}>
        <CellData data={cellData} />
      </Grid>
    ))}
  </>
)

export default () => (
  <div>
    <div
      style={{
        backgroundColor: Colors.secondary,
        textAlign: 'center',
        marginTop: '10px',
      }}>
      <Grid item container xs={12}>
        <Headers data={TextContent.secondSection.restrictions.headers} />
      </Grid>
    </div>

    <Grid
      item
      container
      xs={12}
      style={{
        fontSize: '12px',
      }}>
      {TextContent.secondSection.restrictions.data.map(
        (rowData, rowDataIndex) => (
          <RowContent key={`rowContent-${rowDataIndex}`} data={rowData} />
        )
      )}
    </Grid>
    <Grid item container xs={12} justify="center">
      <Grid item xs={11}>
        <p
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
          }}>
          {TextContent.secondSection.restrictions.footer}
        </p>
      </Grid>
    </Grid>
  </div>
)
